export const NIVEL_ESCOLARIDADE = [
  { id: 1, nivelEscolar: "FUNDAMENTAL-INCOMPLETO" },
  { id: 2, nivelEscolar: "FUNDAMENTAL-COMPLETO" },
  { id: 3, nivelEscolar: "MEDIO-INCOMPLETO" },
  { id: 4, nivelEscolar: "MEDIO-COMPLETO" },
  { id: 5, nivelEscolar: "SUPERIOR-INCOMPLETO" },
  { id: 6, nivelEscolar: "SUPERIOR-COMPLETO" },
  { id: 7, nivelEscolar: "POS-GRADUACAO ESPECIALIZACAO-INCOMPLETO" },
  { id: 8, nivelEscolar: "POS-GRADUACAO ESPECIALIZACAO-COMPLETO" },
  { id: 9, nivelEscolar: "POS-GRADUACAO MESTRADO-INCOMPLETO" },
  { id: 10, nivelEscolar: "POS-GRADUACAO MESTRADO-COMPLETO" },
  { id: 11, nivelEscolar: "POS-GRADUACAO DOUTORADO-INCOMPLETO" },
  { id: 12, nivelEscolar: "POS-GRADUACAO DOUTORADO-COMPLETO" }
];
