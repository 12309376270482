<template>
  <md-table v-model="cadastro" @md-selected="onSelect">
    <div v-if="!isFetching">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submit)"
          @keydown.enter.stop.prevent
        >
          <div style="padding-top:15px;display:inline-block;">
            <span>
              <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
                Situação Cadastral
              </h5></span
            >
          </div>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.situacao"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="situacaoSelect">Selecionar Situação</label>
                  <md-select
                    id="selectOptionFieldSituacao"
                    v-model="situacaoSelect"
                    name="situacaoSelect"
                    @md-selected="selectSituacao"
                  >
                    <md-option
                      v-for="item in situacaoSelectOption"
                      :key="item.id"
                      :label="item.situacao"
                      :value="item.id"
                    >
                      {{ item.situacao }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.nomeEntidade"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-autocomplete
                  v-model="entidadeSelect"
                  :md-options="entidadeSelectOptionAuto"
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  @md-selected="selectedEntidade"
                >
                  <label>Selecionar Entidade</label>
                </md-autocomplete>

                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Dados Pessoais
            </h5></span
          >
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-100">
              <ValidationProvider
                name="form.nomeCompleto"
                :rules="{
                  regex: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1\']*$/,
                  min: 3,
                  max: 100,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome Completo</label>
                  <md-input
                    name="Nome Completo"
                    v-model="form.nomeCompleto"
                    type="text"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.cpfServidor"
                :rules="{
                  regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                  min: 14,
                  max: 14,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>CPF</label>
                  <md-input
                    v-model="form.cpfServidor"
                    type="text"
                    :maxlength="14"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "CPF dever ser no formato 999.999.999-99" }}
                </span>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.dataNascimento"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data de Nascimento</label>
                  <md-input
                    v-model="form.dataNascimento"
                    type="text"
                    :maxlength="10"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.dataObito"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data de Obito</label>
                  <md-input
                    v-model="form.dataObito"
                    type="text"
                    :maxlength="10"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.diaAniversario"
                :rules="{
                  regex: /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/,
                  min: 2,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Dia Aniversário</label>
                  <md-input
                    v-model="form.diaAniversario"
                    type="text"
                    :maxlength="2"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.mesAniversario"
                :rules="{
                  regex: /^(0[1-9]|1[0-2])$/,
                  min: 2,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Mês de Aniversário</label>
                  <md-input
                    v-model="form.mesAniversario"
                    type="text"
                    :maxlength="2"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-field>
                <label>Idade</label>
                <md-input
                  v-model="idade"
                  type="numeric"
                  :maxlength="3"
                  disabled
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.sexoServidor"
                rules="required"
                v-slot="{ errors }"
              >
                <label>Sexo </label>
                <md-radio v-model="form.sexoServidor" value="M"
                  >Masculino</md-radio
                >
                <md-radio v-model="form.sexoServidor" value="F"
                  >Feminino</md-radio
                >
                <br />
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100"></div>

            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.estadoCivil"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="estadoCivilSelect">Selecionar Estado Civil</label>
                  <md-select
                    id="selectOptionFieldEstadoCivil"
                    v-model="estadoCivilSelect"
                    name="estadoCivilSelect"
                    @md-selected="selectEstadoCivil"
                  >
                    <md-option
                      v-for="item in estadoCivilOptions"
                      :key="item.id"
                      :label="item.estadoCivil"
                      :value="item.id"
                    >
                      {{ item.estadoCivil }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.nivelEscolaridade"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="nivelEscolaridadeSelect"
                    >Selecionar Nível Escolaridade</label
                  >
                  <md-select
                    id="selectOptionFieldNivelEscolaridade"
                    v-model="nivelEscolaridadeSelect"
                    name="nivelEscolaridadeSelect"
                    @md-selected="selectNivelEscolaridade"
                  >
                    <md-option
                      v-for="item in nivelEscolaridadeOptions"
                      :key="item.id"
                      :label="item.nivelEscolar"
                      :value="item.id"
                    >
                      {{ item.nivelEscolar }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.nomeMae"
                rules="required|min:3|max:200"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome da Mãe</label>
                  <md-input
                    name="Nome da Mãe"
                    v-model="form.nomeMae"
                    type="text"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Dados Complementares
            </h5></span
          >
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.rgServidor"
                :rules="{
                  min: 8,
                  max: 12
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>RG</label>
                  <md-input
                    v-model="form.rgServidor"
                    type="text"
                    :maxlength="12"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-10">
              <ValidationProvider
                name="form.rgUfServidor"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="rgUfServidorSelect">UF do RG</label>
                  <md-select
                    id="selectOptionFieldRgUf"
                    v-model="rgUfServidorSelect"
                    name="rgUfServidorSelect"
                    @md-selected="selectRgUfServidor"
                  >
                    <md-option
                      v-for="item in estados"
                      :key="item.shortname"
                      :label="item.name"
                      :value="item.shortname"
                    >
                      {{ item.shortname }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.rgOrgExpedidorServ"
                :rules="{
                  min: 2,
                  max: 10
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Orgão Expedidor</label>
                  <md-input
                    v-model="form.rgOrgExpedidorServ"
                    type="text"
                    :maxlength="10"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.rgDataExpServ"
                :rules="{
                  min: 10,
                  max: 10
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data de emisão do RG</label>
                  <md-input
                    v-model="form.rgDataExpServ"
                    type="text"
                    :maxlength="10"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100"></div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.numTitEleitor"
                :rules="{
                  regex: /(\d{4}).(\d{4}).[0-2][0-9](\d{2})/,
                  min: 14,
                  max: 14
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Titulo Eleitor</label>
                  <md-input
                    v-model="form.numTitEleitor"
                    type="text"
                    :maxlength="14"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Titulo Eleitor deve ter 14 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.ctpsNum"
                :rules="{
                  regex: /^(?:\d{6}|\d{7})$/,
                  numeric: true,
                  min: 6,
                  max: 7
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Carteira de Trabalho</label>
                  <md-input
                    v-model="form.ctpsNum"
                    type="text"
                    :maxlength="7"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.ctpsSerie"
                :rules="{
                  regex: /^(?:\d{2}|\d{4})$/,
                  numeric: true,
                  min: 2,
                  max: 4
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Serie CTPS</label>
                  <md-input
                    v-model="form.ctpsSerie"
                    type="text"
                    :maxlength="4"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.ctpsUf"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="ctpsUfSelect">UF da CTPS</label>
                  <md-select
                    id="selectOptionFieldCtpsUf"
                    v-model="ctpsUfSelect"
                    name="ctpsUfSelect"
                    @md-selected="selectCtpsUf"
                  >
                    <md-option
                      v-for="item in estados"
                      :key="item.shortname"
                      :label="item.name"
                      :value="item.shortname"
                    >
                      {{ item.shortname }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.pisPasep"
                :rules="{
                  regex: /^(\d{3}).(\d{5}).(\d{2})-(\d{1})$/,
                  min: 14,
                  max: 14
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>PIS/PASEP</label>
                  <md-input
                    v-model="form.pisPasep"
                    type="text"
                    :maxlength="14"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "PIS/PASEP deve ter 14 digitos." }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 text-right">
              <md-button type="submit" class="md-raised md-success mt-4">
                CRIAR
              </md-button>
              &nbsp;
              <md-button
                @click="validarProximo()"
                class="md-raised md-success mt-4"
              >
                PROXIMO >
              </md-button>
              &nbsp;
              <md-button
                type="reset"
                class="md-raised mt-4"
                @click="cancelarButton"
              >
                CANCELAR
              </md-button>
              <span>&nbsp;</span>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </md-table>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { dataMask } from "@/pages/Dashboard/js/dataMask";
import { tituloEleitorMask } from "@/pages/Dashboard/js/tituloEleitorMask";
import { pispasepMask } from "@/pages/Dashboard/js/pispasepMask";
import { ESTADOS } from "@/pages/Dashboard/js/estados";
import { SITUACAO } from "@/pages/Dashboard/js/situacao";
import { ESTADO_CIVIL } from "@/pages/Dashboard/js/estadoCivil";
import { NIVEL_ESCOLARIDADE } from "@/pages/Dashboard/js/nivelEscolaridade";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { checkAllResponses } from "../js/checkAllResponses";

let pt_br = pt_BR;
let names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex = "Campo com formato inválido.";
  }
}

let fields = {
  "form.nomeCompleto": {
    regex:
      "O campo Nome Completo não pode ter numeros, nem caracteres especiais."
  },
  "form.ctpsNum": {
    regex: "Carteira de Trabalho deve ter de 6 a 7 digitos."
  },
  "form.ctpsSerie": {
    regex: "Serie CTPS deve ter de 2 a 4 digitos."
  }
};

for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
Vue.set(pt_br, "fields", fields);
localize("pt_BR", pt_br);

export default {
  /*props: {
    isEdition: {
      type: Boolean
    }
  },*/
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    user_profile_state: state => state.user_profile
    /*isFormValid() {
      let check = true;
      check = Object.keys(this.form).some(key => {
        if (this.form[key] !== null) {
          //console.log(this.$refs.observer);
          if (this.$refs.observer.errors) {
            let checkerror = Object.keys(this.$refs.observer.errors).some(
              key => {
                let checkerrorArray =
                  this.$refs.observer.errors[key].length > 0 ? true : false;
                return checkerrorArray;
              }
            );
            return checkerror;
          }
          return true;
        } else {
          return false;
        }
      });
      let count = 0;
      Object.keys(this.form).map(key => {
        if (this.form[key] !== null) {
          count = count + 1;
        }
      });
      if (count == 0) {
        check = true;
      }
      console.log(check);
      return check;
    }*/
  },
  data() {
    return {
      entidadeSelect: "",
      entidadeSelectOptionAuto: [],
      entidadeSelectOption: [],
      selectedServidorId: this.id,
      cadastro: null,
      situacaoSelectOption: SITUACAO,
      estadoCivilOptions: ESTADO_CIVIL,
      nivelEscolaridadeOptions: NIVEL_ESCOLARIDADE,
      isFetching: true,
      isFetching3: true,
      estados: ESTADOS,
      nivelEscolaridadeSelect: "",
      estadoCivilSelect: "",
      situacaoSelect: "",
      rgUfServidorSelect: "",
      ctpsUfSelect: "",
      idade: 0,
      radioSexo: false,
      status: false,
      mensagem: "",
      form: {
        idEntidade: null,
        nomeEntidade: null,
        nomeCompleto: null,
        situacao: null,
        cpfServidor: null,
        numTitEleitor: null,
        ctpsNum: null,
        ctpsSerie: null,
        ctpsUf: null,
        estadoCivil: null,
        nivelEscolaridade: null,
        dataNascimento: null,
        dataObito: null,
        diaAniversario: null,
        mesAniversario: null,
        pisPasep: null,
        rgServidor: null,
        rgUfServidor: null,
        rgOrgExpedidorServ: null,
        rgDataExpServ: null,
        sexoServidor: null,
        nomeMae: null,
        siapeServidor: null,
        siapeServidorDv: null,
        codOrgao: null,
        orgDescricao: null,
        upag: null,
        upagDescricao: null,
        siglaUpag: null,
        ufUpag: null,
        grupoCargo: null,
        cargoDescricao: null,
        classeCargo: null,
        padraoCargo: null,
        nivelCargo: null,
        uorgLotacao: null,
        nomeUnidadeLotacao: null,
        siglaUnidadeLotacao: null,
        ufUnidadeLotacao: null,
        uorgLocalizacao: null,
        nomeUnidadeLocalizacao: null,
        siglaUnidadeLocalizacao: null,
        ufUnidadeLocalizacao: null,
        uorgExercicio: null,
        nomeUnidadeExercicio: null,
        siglaUnidadeExercicio: null,
        ufUnidadeExercicio: null,
        sigRegJur: null,
        regimeJuridico: null,
        descricaoIngOrgao: null,
        dataIngressoOrgao: null,
        descricaoIngServPublico: null,
        dtIngServPublico: null,
        grupoAposentadoria: null,
        codAposentadoria: null,
        ocorrenciaAposentadoria: null,
        dtAposentadoria: null,
        codExclusao: null,
        ocorrenciaExclusao: null,
        dtExclusao: null
      },
      papel: ""
    };
  },
  beforeMount() {
    this.getPapel();
    this.getEntidadeAll().then(() => {
      this.loadServidor();
    });
  },
  methods: {
    ...mapActions("papel", ["ActionGetPapelAll"]),
    ...mapActions("servidor_publico", [
      "ActionSetSERVIDOR_PUBLICO",
      "ActionGetServidorPublicoById",
      "ActionPOSTServidorPublico",
      "ActionSubmitServidorAllData"
    ]),
    ...mapActions("entidade", [
      "ActionGetEntidadeById",
      "ActionGetEntidadeAll"
    ]),
    async loadForm() {
      if (this.servidorPublicoSelected.idEntidade) {
        this.form.idEntidade = this.servidorPublicoSelected.idEntidade;
        this.form.nomeEntidade = this.servidorPublicoSelected.nomeEntidade;
        this.form.siapeServidor =
          this.servidorPublicoSelected.siapeServidor === null
            ? null
            : this.servidorPublicoSelected.siapeServidor.trim();

        this.form.siapeServidorDv =
          this.servidorPublicoSelected.siapeServidorDv === null
            ? null
            : this.servidorPublicoSelected.siapeServidorDv.toString().trim();

        this.form.codOrgao = this.servidorPublicoSelected.codOrgao;
        this.form.orgDescricao =
          this.servidorPublicoSelected.orgDescricao === null
            ? null
            : this.servidorPublicoSelected.orgDescricao.toString().trim();
        this.form.upag = this.servidorPublicoSelected.upag;
        this.form.upagDescricao =
          this.servidorPublicoSelected.upagDescricao === null
            ? null
            : this.servidorPublicoSelected.upagDescricao.toString().trim();
        this.form.siglaUpag =
          this.servidorPublicoSelected.siglaUpag === null
            ? null
            : this.servidorPublicoSelected.siglaUpag.toString().trim();

        if (this.servidorPublicoSelected.ufUpag !== null) {
          let ufUpag = this.servidorPublicoSelected.ufUpag;
          let index = -1;
          let resultItem = this.estados.find(function(item, i) {
            if (item.shortname === ufUpag) {
              index = i;
              return item;
            }
          });
          this.form.ufUpag = resultItem.shortname;
          this.ufUpagSelect = resultItem.shortname;
        } else {
          this.form.ufUpag = null;
          this.ufUpagSelect = null;
        }

        this.form.uorgExercicio =
          this.servidorPublicoSelected.uorgExercicio === null
            ? null
            : this.servidorPublicoSelected.uorgExercicio.toString().trim();
        this.form.nomeUnidadeExercicio =
          this.servidorPublicoSelected.nomeUnidadeExercicio === null
            ? null
            : this.servidorPublicoSelected.nomeUnidadeExercicio
                .toString()
                .trim();
        this.form.siglaUnidadeExercicio =
          this.servidorPublicoSelected.siglaUnidadeExercicio === null
            ? null
            : this.servidorPublicoSelected.siglaUnidadeExercicio
                .toString()
                .trim();

        if (this.servidorPublicoSelected.ufUnidadeExercicio !== null) {
          let ufExerc = this.servidorPublicoSelected.ufUnidadeExercicio;
          let index = -1;
          let resultItem = this.estados.find(function(item, i) {
            if (item.shortname === ufExerc) {
              index = i;
              return item;
            }
          });
          this.form.ufUnidadeExercicio = resultItem.shortname;
          this.ufUnidadeExercicioSelect = resultItem.shortname;
        } else {
          this.form.ufUnidadeExercicio = null;
          this.ufUnidadeExercicioSelect = null;
        }

        this.form.grupoCargo =
          this.servidorPublicoSelected.grupoCargo === null
            ? null
            : this.servidorPublicoSelected.grupoCargo.toString().trim();
        this.form.cargoDescricao =
          this.servidorPublicoSelected.cargoDescricao === null
            ? null
            : this.servidorPublicoSelected.cargoDescricao.toString().trim();
        this.form.classeCargo =
          this.servidorPublicoSelected.classeCargo === null
            ? null
            : this.servidorPublicoSelected.classeCargo.toString().trim();
        this.form.padraoCargo =
          this.servidorPublicoSelected.padraoCargo === null
            ? null
            : this.servidorPublicoSelected.padraoCargo.toString().trim();
        this.form.nivelCargo =
          this.servidorPublicoSelected.nivelCargo === null
            ? null
            : this.servidorPublicoSelected.nivelCargo.toString().trim();

        this.form.uorgLotacao = this.servidorPublicoSelected.uorgLotacao;
        this.form.nomeUnidadeLotacao =
          this.servidorPublicoSelected.nomeUnidadeLotacao === null
            ? null
            : this.servidorPublicoSelected.nomeUnidadeLotacao;
        this.form.siglaUnidadeLotacao =
          this.servidorPublicoSelected.siglaUnidadeLotacao === null
            ? null
            : this.servidorPublicoSelected.siglaUnidadeLotacao;

        if (
          this.servidorPublicoSelected.ufUnidadeLotacao !== null &&
          this.servidorPublicoSelected.ufUnidadeLotacao
        ) {
          let ufUnidadeLotacao = this.servidorPublicoSelected.ufUnidadeLotacao;
          let index = -1;
          let resultItem = this.estados.find(function(item, i) {
            if (item.shortname === ufUnidadeLotacao) {
              index = i;
              return item;
            }
          });
          this.form.ufUnidadeLotacao = resultItem.shortname;
          this.ufUnidadeLotacaoSelect = resultItem.shortname;
        } else {
          this.form.ufUnidadeLotacao = null;
          this.ufUnidadeLotacaoSelect = null;
        }

        this.form.uorgLocalizacao =
          this.servidorPublicoSelected.uorgLocalizacao === null
            ? null
            : this.servidorPublicoSelected.uorgLocalizacao.toString().trim();

        this.form.nomeUnidadeLocalizacao =
          this.servidorPublicoSelected.nomeUnidadeLocalizacao === null
            ? null
            : this.servidorPublicoSelected.nomeUnidadeLocalizacao
                .toString()
                .trim();
        this.form.siglaUnidadeLocalizacao =
          this.servidorPublicoSelected.siglaUnidadeLocalizacao === null
            ? null
            : this.servidorPublicoSelected.siglaUnidadeLocalizacao
                .toString()
                .trim();

        if (
          this.servidorPublicoSelected.ufUnidadeLocalizacao !== null &&
          this.servidorPublicoSelected.ufUnidadeLocalizacao
        ) {
          let ufUnidadeLocalizacao = this.servidorPublicoSelected
            .ufUnidadeLocalizacao;
          let index = -1;
          let resultItem = this.estados.find(function(item, i) {
            if (item.shortname === ufUnidadeLocalizacao) {
              index = i;
              return item;
            }
          });
          this.form.ufUnidadeLocalizacao = resultItem.shortname;
          this.ufUnidadeLocalizacaoSelect = resultItem.shortname;
        } else {
          this.form.ufUnidadeLocalizacao = null;
          this.ufUnidadeLocalizacaoSelect = null;
        }

        this.form.descricaoIngOrgao =
          this.servidorPublicoSelected.descricaoIngOrgao === null
            ? null
            : this.servidorPublicoSelected.descricaoIngOrgao.toString().trim();
        this.form.dataIngressoOrgao = this.servidorPublicoSelected.dataIngressoOrgao;

        this.form.descricaoIngServPublico =
          this.servidorPublicoSelected.descricaoIngServPublico === null
            ? null
            : this.servidorPublicoSelected.descricaoIngServPublico
                .toString()
                .trim();
        this.form.dtIngServPublico = this.servidorPublicoSelected.dtIngServPublico;

        this.form.sigRegJur =
          this.servidorPublicoSelected.sigRegJur === null
            ? null
            : this.servidorPublicoSelected.sigRegJur.toString().trim();

        this.form.regimeJuridico =
          this.servidorPublicoSelected.regimeJuridico === null
            ? null
            : this.servidorPublicoSelected.regimeJuridico;

        this.form.grupoAposentadoria =
          this.servidorPublicoSelected.grupoAposentadoria === null
            ? null
            : this.servidorPublicoSelected.grupoAposentadoria.toString().trim();

        this.form.codAposentadoria =
          this.servidorPublicoSelected.codAposentadoria === null
            ? null
            : this.servidorPublicoSelected.codAposentadoria.toString().trim();

        this.form.ocorrenciaAposentadoria =
          this.servidorPublicoSelected.ocorrenciaAposentadoria === null
            ? null
            : this.servidorPublicoSelected.ocorrenciaAposentadoria
                .toString()
                .trim();

        this.form.dtAposentadoria =
          this.servidorPublicoSelected.dtAposentadoria === null
            ? null
            : this.servidorPublicoSelected.dtAposentadoria;

        this.form.codExclusao =
          this.servidorPublicoSelected.codExclusao === null
            ? null
            : this.servidorPublicoSelected.codExclusao.toString().trim();

        this.form.ocorrenciaExclusao =
          this.servidorPublicoSelected.ocorrenciaExclusao === null
            ? null
            : this.servidorPublicoSelected.ocorrenciaExclusao.toString().trim();

        this.form.dtExclusao = this.servidorPublicoSelected.dtExclusao;

        if (
          this.servidorPublicoSelected.situacao !== null &&
          this.servidorPublicoSelected.situacao
        ) {
          let index = -1;
          let situacao = this.servidorPublicoSelected.situacao;
          let resultItem = this.situacaoSelectOption.find(function(item, i) {
            if (item.situacao === situacao) {
              index = i;
              return item;
            }
          });
          this.form.situacao = resultItem.situacao;
          this.situacaoSelect = resultItem.id;
        } else {
          this.form.situacao = null;
          this.situacaoSelect = null;
        }

        this.form.nomeCompleto = this.servidorPublicoSelected.nomeCompleto;

        this.form.cpfServidor = this.servidorPublicoSelected.cpfServidor;
        this.form.dataNascimento = this.servidorPublicoSelected.dataNascimento;
        this.form.diaAniversario = this.servidorPublicoSelected.diaAniversario;
        this.form.mesAniversario = this.servidorPublicoSelected.mesAniversario;
        this.form.dataObito = this.servidorPublicoSelected.dataObito;
        this.radioSexo = this.servidorPublicoSelected.sexoServidor;
        this.form.sexoServidor = this.servidorPublicoSelected.sexoServidor;
        this.form.rgServidor = this.servidorPublicoSelected.rgServidor;

        if (
          this.servidorPublicoSelected.rgUfServidor !== null &&
          this.servidorPublicoSelected.rgUfServidor
        ) {
          let rgUfServidor = this.servidorPublicoSelected.rgUfServidor;
          let index = -1;
          let resultItem = this.estados.find(function(item, i) {
            if (item.shortname === rgUfServidor) {
              index = i;
              return item;
            }
          });
          this.form.rgUfServidor = resultItem.shortname;
          this.rgUfServidorSelect = resultItem.shortname;
        } else {
          this.form.rgUfServidor = null;
          this.rgUfServidorSelect = null;
        }

        this.form.rgOrgExpedidorServ = this.servidorPublicoSelected.rgOrgExpedidorServ;
        this.form.rgDataExpServ = this.servidorPublicoSelected.rgDataExpServ;

        if (
          this.servidorPublicoSelected.estadoCivil !== null &&
          this.servidorPublicoSelected.estadoCivil
        ) {
          let index = -1;
          let estadoCivil = this.servidorPublicoSelected.estadoCivil;
          let resultItem = this.estadoCivilOptions.find(function(item, i) {
            if (item.estadoCivil === estadoCivil) {
              index = i;
              return item;
            }
          });
          this.form.estadoCivil = resultItem.estadoCivil;
          this.estadoCivilSelect = resultItem.id;
        } else {
          this.form.estadoCivil = null;
          this.estadoCivilSelect = null;
        }

        if (
          this.servidorPublicoSelected.nivelEscolaridade !== null &&
          this.servidorPublicoSelected.nivelEscolaridade
        ) {
          let index = -1;
          let nivelEscolaridade = this.servidorPublicoSelected
            .nivelEscolaridade;
          let resultItem = this.nivelEscolaridadeOptions.find(function(
            item,
            i
          ) {
            if (item.nivelEscolar === nivelEscolaridade) {
              index = i;
              return item;
            }
          });
          this.form.nivelEscolaridade = resultItem.nivelEscolar;
          this.nivelEscolaridadeSelect = resultItem.id;
        } else {
          this.form.nivelEscolaridade = null;
          this.nivelEscolaridadeSelect = null;
        }

        this.form.nomeMae = this.servidorPublicoSelected.nomeMae;

        this.form.numTitEleitor = this.servidorPublicoSelected.numTitEleitor;
        this.form.ctpsNum = this.servidorPublicoSelected.ctpsNum;
        this.form.ctpsSerie = this.servidorPublicoSelected.ctpsSerie;
        this.form.ctpsUf = this.servidorPublicoSelected.ctpsUf;

        if (
          this.servidorPublicoSelected.ctpsUf !== null &&
          this.servidorPublicoSelected.ctpsUf
        ) {
          let ctpsUf = this.servidorPublicoSelected.ctpsUf;
          let index = -1;
          let resultItem = this.estados.find(function(item, i) {
            if (item.shortname === ctpsUf) {
              index = i;
              return item;
            }
          });
          this.form.ctpsUf = resultItem.shortname;
          this.ctpsUfSelect = resultItem.shortname;
        } else {
          this.form.ctpsUf = null;
          this.ctpsUfSelect = null;
        }

        this.form.pisPasep = this.servidorPublicoSelected.pisPasep;
        this.form.diaAniversario = this.servidorPublicoSelected.diaAniversario;
        this.form.mesAniversario = this.servidorPublicoSelected.mesAniversario;

        if (
          this.servidorPublicoSelected.nomeEntidade !== null &&
          this.servidorPublicoSelected.nomeEntidade
        ) {
          let nomeEntidade = this.servidorPublicoSelected.nomeEntidade;
          let index = -1;
          let resultItem = this.entidadeSelectOption.find(function(item, i) {
            if (item.nomeEntidade === nomeEntidade) {
              index = i;
              return item;
            }
          });
          this.form.idEntidade = resultItem.idEntidade;
          this.form.nomeEntidade = resultItem.nomeEntidade;
          this.entidadeSelect = resultItem.nomeEntidade;
        } else {
          this.form.idEntidade = null;
          this.entidadeSelect = null;
          this.entidadeSelect = null;
        }
      }
      this.isFetching = false;
      let copiedJSON = Object.keys(this.entidadeSelectOption).map(key => {
        return this.entidadeSelectOption[key].nomeEntidade;
      });
      this.entidadeSelectOptionAuto = copiedJSON;
    },

    async loadServidor() {
      this.loadForm();
    },
    async getEntidadeById(idEntidade) {
      try {
        let id = idEntidade;
        let papel = this.papel;
        let response = await this.ActionGetEntidadeById({ id, papel });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrada")) {
              Swal.fire({
                title: `idEntidade: ${idEntidade} não encontrada.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idEntidade: ${idEntidade}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            return response.body;
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    async getServidorById(idServidor, papelUsuario) {
      let id = idServidor;
      let papel = papelUsuario;
      try {
        let response = await this.ActionGetServidorPublicoById({ id, papel });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrado")) {
              Swal.fire({
                title: `idServidor: ${idServidor} não encontrado.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idServidor: ${idServidor}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            return response.body;
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    selectCtpsUf(item) {
      let ctpsUf = item;
      let index = -1;
      let resultItem = this.estados.find(function(item, i) {
        if (item.shortname === ctpsUf) {
          index = i;
          return item;
        }
      });
      this.form.ctpsUf = resultItem.shortname;
    },
    selectRgUfServidor(item) {
      let rgUfServidor = item;
      let index = -1;
      let resultItem = this.estados.find(function(item, i) {
        if (item.shortname === rgUfServidor) {
          index = i;
          return item;
        }
      });
      this.form.rgUfServidor = resultItem.shortname;
    },
    selectEstadoCivil(item) {
      let estadoCivil = item;
      let index3 = -1;
      let resultItem3 = this.estadoCivilOptions.find(function(item, i) {
        if (item.id === estadoCivil) {
          index3 = i;
          return item;
        }
      });
      this.form.estadoCivil = resultItem3.estadoCivil;
    },
    selectNivelEscolaridade(item) {
      let nivelEscolaridade = item;
      let index2 = -1;
      let resultItem2 = this.nivelEscolaridadeOptions.find(function(item, i) {
        if (item.id === nivelEscolaridade) {
          index2 = i;
          return item;
        }
      });
      this.form.nivelEscolaridade = resultItem2.nivelEscolar;
    },
    onSelect(item) {
      console.log(item);
    },
    selectSituacao(item) {
      let situacao = item;
      let index = -1;
      let resultItem = this.situacaoSelectOption.find(function(item, i) {
        if (item.id === situacao) {
          index = i;
          return item;
        }
      });
      this.form.situacao = resultItem.situacao;
    },
    calcularIdade(dobString) {
      let dob = new Date(dobString);
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let birthdayThisYear = new Date(
        currentYear,
        dob.getMonth(),
        dob.getDate()
      );
      let age = currentYear - dob.getFullYear();
      if (birthdayThisYear > currentDate) {
        age--;
      }
      return age;
    },
    callIdadeCalc(dataNascimento) {
      if (dataNascimento.toString().length < 10) {
        return 0;
      }
      let data = dataNascimento;
      let partes = data.split("/");
      let join = partes[2] + "-" + partes[1] + "-" + partes[0];
      return this.calcularIdade(join);
    },
    VerificaCPF(strCPF) {
      let Soma;
      let Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    async submit() {
      try {
        this.ActionSetSERVIDOR_PUBLICO(this.form);
        let papel = this.papel;
        let form = this.form;
        this.ActionSubmitServidorAllData({
          papel
        }).then(response => {
          checkAllResponses(response);
        });
        /*let response = await this.ActionPOSTServidorPublico({
          papel,
          form
        });*/
      } catch (err) {
        this.handleError(err);
      }
    },
    emitToParent(value) {
      this.$emit("nextRoute", value);
    },
    cancelarButton() {
      this.resetForm();
    },
    validarProximo() {
      //this.$refs.observer.validate({
      //  silent: true
      // });
      let checkerror = true;
      this.$refs.observer.validate();
      this.delay(1000).then(() => {
        if (this.$refs.observer.errors) {
          checkerror = Object.keys(this.$refs.observer.errors).some(key => {
            let checkerrorArray =
              this.$refs.observer.errors[key].length > 0 ? true : false;
            return checkerrorArray;
          });
          if (!checkerror) {
            this.ActionSetSERVIDOR_PUBLICO(this.form);
            this.emitToParent("vinculos");
            window._Vue.$router
              .push({ name: "Novos Vinculos" })
              .catch(err => {});
          }
        }
      });
    },
    resetForm() {
      Object.keys(this.form).map(key => {
        return (this.form[key] = "");
      });
      this.ctpsUfSelect = undefined;
      this.rgUfServidorSelect = undefined;
      this.estadoCivilSelect = undefined;
      this.nivelEscolaridadeSelect = undefined;
      this.situacaoSelect = undefined;
      this.entidadeSelect = undefined;
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    selectedEntidade(item) {
      let nomeEntidade = item;
      let index = -1;
      let resultItem = this.entidadeSelectOption.find(function(item, i) {
        if (item.nomeEntidade === nomeEntidade) {
          index = i;
          return item;
        }
      });
      this.form.idEntidade = resultItem.idEntidade;
      this.form.nomeEntidade = item;
    },
    async getEntidadeAll() {
      try {
        let papel = this.papel;
        await this.ActionGetEntidadeAll({ papel }).then(res => {
          let result = res.body;
          result.sort((a, b) => {
            if (a.nomeEntidade < b.nomeEntidade) return -1;
            if (a.nomeEntidade > b.nomeEntidade) return 1;
            return 0;
          });
          this.entidadeSelectOption = result;
          this.isFetching3 = false;
          return this.entidadeSelectOption;
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  watch: {
    "form.cpfServidor": function(val, oldVal) {
      let cpf = cpfMask(val);
      this.form.cpfServidor = cpf;
    },
    "form.dataNascimento": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dataNascimento = data;
      this.idade = this.callIdadeCalc(this.form.dataNascimento);
      let diaMes = this.form.dataNascimento.split("/");
      this.form.diaAniversario = diaMes[0];
      this.form.mesAniversario = diaMes[1];
    },
    "form.dataObito": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dataObito = data;
    },
    /*"form.rgUfServidor": function(val, oldVal) {
      let rgUF = val.toString().toUpperCase();
      this.form.rgUfServidor = rgUF;
    },*/
    "form.rgDataExpServ": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.rgDataExpServ = data;
    },
    /*"form.nomeCompleto": function(val, oldVal) {
      let nomeCompleto = val.toString().toUpperCase();
      this.form.nomeCompleto = nomeCompleto;
    },*/
    /*"form.nomeMae": function(val, oldVal) {
      let nomeMae = val.toString().toUpperCase();
      this.form.nomeMae = nomeMae;
    },*/
    "form.numTitEleitor": function(val, oldVal) {
      let numTitEleitor = tituloEleitorMask(val.toString());
      this.form.numTitEleitor = numTitEleitor;
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: uppercase;
}

::v-deep .md-radio.md-checked .md-radio-container {
  border-color: #000000 !important;
}

::v-deep .md-radio.md-checked .md-radio-container:after {
  background-color: #000000 !important;
}
</style>
